import React, { Component } from 'react';
import Lightbox from 'react-images';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

// Import required photos
import leelo_5948 from '../images/photos/leelo_5948.jpg';
import leelo_5948_tn from '../images/photos/leelo_5948_tn.jpg';
import leelo_6112 from '../images/photos/leelo_6112.jpg';
import leelo_6112_tn from '../images/photos/leelo_6112_tn.jpg';
import leelo_6079 from '../images/photos/leelo_6079.jpg';
import leelo_6079_tn from '../images/photos/leelo_6079_tn.jpg';
import leelo_5935 from '../images/photos/leelo_5935.jpg';
import leelo_5935_tn from '../images/photos/leelo_5935_tn.jpg';
import leelo_5959 from '../images/photos/leelo_5959.jpg';
import leelo_5959_tn from '../images/photos/leelo_5959_tn.jpg';
import leelo_7555 from '../images/photos/leelo_7555.jpg';
import leelo_7555_tn from '../images/photos/leelo_7555_tn.jpg';
import leelo_denys from '../images/photos/leelo_denys.jpg';
import leelo_denys_tn from '../images/photos/leelo_denys_tn.jpg';
import leelo_5814 from '../images/photos/leelo_5814.jpg';
import leelo_5814_tn from '../images/photos/leelo_5814_tn.jpg';

// Define photos for gallery
const photos = [
  {
    src: leelo_5948,
    thumbnail: leelo_5948_tn,
    width: 140,
    height: 210,
    alt: 'Leelo Pascal',
  },
  {
    src: leelo_6112,
    thumbnail: leelo_6112_tn,
    width: 140,
    height: 210,
    alt: 'Leelo Pascal',
  },
  {
    src: leelo_6079,
    thumbnail: leelo_6079_tn,
    width: 140,
    height: 203,
    alt: 'Leelo Pascal',
  },
  {
    src: leelo_5935,
    thumbnail: leelo_5935_tn,
    width: 140,
    height: 210,
    alt: 'Leelo Pascal',
  },
  {
    src: leelo_5959,
    thumbnail: leelo_5959_tn,
    width: 140,
    height: 210,
    alt: 'Leelo Pascal',
  },
  {
    src: leelo_7555,
    thumbnail: leelo_7555_tn,
    width: 140,
    height: 210,
    alt: 'Leelo Pascal',
  },
  {
    src: leelo_denys,
    thumbnail: leelo_denys_tn,
    width: 292,
    height: 164,
    alt: 'Leelo Pascal',
  },
  {
    src: leelo_5814,
    thumbnail: leelo_5814_tn,
    width: 210,
    height: 164,
    alt: 'Leelo Pascal',
  },
];

// Create a gallery from the photos array
const Gallery = ({ photos, context }) => (
  <>
    {photos.map((photo, index) => (
      <span className="imgFrame" key={index}>
        <img
          onClick={() => context.openLightbox(index)}
          alt={photo.alt}
          width={photo.width}
          height={photo.height}
          src={photo.thumbnail}
        />
      </span>
    ))}
  </>
);

class PhotosPage extends Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(index) {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {
    return (
      <Layout>
        <SEO title="Photos" />
        <Helmet bodyAttributes={{ class: 'photos' }} />
        <div className="staticContent" id="staticContent_photos">
          <h1>Photos</h1>
          <Lightbox
            images={photos}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
          <p>
            <Gallery photos={photos} context={this} />
          </p>
        </div>
      </Layout>
    );
  }
}

export default PhotosPage;
